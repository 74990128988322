import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-snowflake',
  template: '',
  styleUrls: []
})
export class SnowflakeComponent implements OnInit {
  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.createSnowflakes();
  }

  createSnowflakes() {
    const snowflakeContainer = this.renderer.createElement('div');
    snowflakeContainer.style.position = 'fixed';
    snowflakeContainer.style.top = '0';
    snowflakeContainer.style.left = '0';
    snowflakeContainer.style.width = '100%';
    snowflakeContainer.style.height = '100%';
    snowflakeContainer.style.pointerEvents = 'none';
    this.renderer.appendChild(document.body, snowflakeContainer);

    for (let i = 0; i < 100; i++) {
      const snowflake = this.renderer.createElement('div');
      snowflake.textContent = '❄';
      snowflake.className = 'snowflake';
      snowflake.style.left = `${Math.random() * 100}vw`;
      snowflake.style.animationDuration = `${Math.random() * 3 + 2}s`; // 2-5 seconds
      snowflake.style.animationDelay = `${Math.random() * 5}s`; // Up to 5s delay
      snowflake.style.fontSize = `${Math.random() * 10 + 10}px`; // 10px to 20px

      snowflake.style.color = 'white'; 
    snowflake.style.opacity = '0.1';

      this.renderer.appendChild(snowflakeContainer, snowflake);
    }
  }
}